<template>
    <div class="goals" :class="currentRouteModel" id="nav-goals" role="tabpanel" aria-labelledby="nav-goals-tab">
        <backgrounds v-if="!widget"></backgrounds>
        <div class="content d-flex flex-column" v-if="!widget">
            <navigation></navigation>
          <section class="count flex-fill"
                   :id="'nav-goals-' + currentRouteModel.substring(5)"
                   role="tabpanel"
                   :aria-labelledby="'nav-goals-tab-' + currentRouteModel.substring(5)"
                    tabindex="0">
                <div class="border_sm">
                    <div class="row h-100 no-gutters justify-content-center">
                        <div class="col-sm-10 col-md-5 col-xl-4">
                            <div class="d-flex align-items-center h-100 flex-column">
                                <div class="section_label">2030 Goal</div>
                                <div class="section_content h-100">
                                    <div class="goals">
                                        <transition name="slide-fade">
                                            <div class="goal_info" v-if="currentRouteModel === 'goalsWater'" key="water">
                                                <div class="goal_content">
                                                    <h6>By 2030, we will help conserve <strong>300{{ '\u00a0' }}billion</strong> gallons of water a{{ '\u00a0' }}year</h6>
                                                    <div class="dynamic_factoid">
                                                        <h5>equivalent to the annual drinking water needs{{ '\u00a0' }}of{{ '\u00a0' }}more{{ '\u00a0' }}than</h5>
                                                        <h3>1 billion people</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goal_info" v-else-if="currentRouteModel === 'goalsFood'" key="food">
                                                <div class="goal_content">
                                                    <h6>By 2030, we will help customers provide an entire year’s worth of high-quality and safe food to <strong>2{{ '\u00a0' }}billion</strong> people each{{ '\u00a0' }}year.</h6>
                                                    <div class="dynamic_factoid">
                                                        <h5>and help prevent foodborne illnesses{{ '\u00a0' }}for</h5>
                                                        <h3>11 million people</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goal_info" v-else-if="currentRouteModel === 'goalsHealth'" key="health">
                                                <div class="goal_content">
                                                    <h6>By 2030, we will help clean <strong>90{{ '\u00a0' }}billion</strong> pairs of hands a{{ '\u00a0' }}year</h6>
                                                    <div class="dynamic_factoid">
                                                        <h5>additionally help provide safe medical care{{ '\u00a0' }}for</h5>
                                                        <h3>116 million people</h3>
                                                        <h5>and reduce more than</h5>
                                                        <h3>1.7 million infections</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goal_info" v-else-if="currentRouteModel === 'goalsClimate'" key="climate">
                                                <div class="goal_content">
                                                    <h6>By 2030, we will help reduce greenhouse gas emissions by <strong>6{{ '\u00a0' }}million</strong> metric tons a{{ '\u00a0' }}year</h6>
                                                    <div class="dynamic_factoid">
                                                        <h5>equivalent to reducing pollution-related illnesses{{ '\u00a0' }}for</h5>
                                                        <h3>10 million people</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 col-xl-8">
                            <div class="d-flex align-items-center h-100 flex-column">
                                <div class="section_content h-100 order-md-2">
                                    <div class="bar_chart_wrapper">
                                        <bar-chart v-if="isDataLoaded"></bar-chart>
                                    </div>
                                </div>
                                <div class="section_label goal_progress">Goal Progress</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="content" v-else-if="widget">
            <a :href="fullScreenLink" class="widget_fullscreen_link widget-component-iframe" target="_blank" title="fullscreen" aria-label="Fullscreen Mode">Fullscreen</a>
            <section class="h-100 count">
                <div class="border_lg pt-0 d-flex flex-column">
                    <div class="section_label" v-if="currentRouteModel === 'goalsWater'" key="water">
                        <div class="d-flex align-items-center">
                            <div class="icon">
                                <img src="@/img/icons/water.svg" alt="" />
                            </div>
                            <h2 class="text">
                                Water
                            </h2>
                        </div>
                    </div>
                    <div class="section_label" v-if="currentRouteModel === 'goalsHealth'" key="health">
                        <div class="d-flex align-items-center">
                            <div class="icon">
                                <img src="@/img/icons/health.svg" alt=""/>
                            </div>
                            <h2 class="text">
                                Health
                            </h2>
                        </div>
                    </div>
                    <div class="section_label" v-if="currentRouteModel === 'goalsFood'" key="food">
                        <div class="d-flex align-items-center">
                            <div class="icon">
                                <img src="@/img/icons/food.svg" alt=""/>
                            </div>
                            <h2 class="text">
                                Food
                            </h2>
                        </div>
                    </div>
                    <div class="section_label" v-if="currentRouteModel === 'goalsClimate'" key="climate">
                        <div class="d-flex align-items-center">
                            <div class="icon">
                                <img src="@/img/icons/climate.svg" alt="" />
                            </div>
                            <h2 class="text">
                                Climate
                            </h2>
                        </div>
                    </div>
                    <div class="flex-fill d-flex align-items-center">
                        <widget-bar-chart v-if="isDataLoaded" ></widget-bar-chart>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import navigation from './navigation.vue';
import barChart from '@/components/goals/NewBarChart';
import widgetBarChart from '@/components/goals/WidgetBarChart';
import backgrounds from "@/components/Backgrounds.vue";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import URL from "@/util/url.js";
export default {
    data () {
        return {
            isDataLoaded : false,
            yearToCalculate : new Date().getFullYear(),
            timer : false
        }
    },
    destroyed() {
          clearInterval(this.timer)
    },
    watch: {
        '$route' (to, from) {
            clearInterval(this.timer)
            this.init();
        }
    },
    created(){
       clearInterval(this.timer)
       this.init();
    },
    methods: {
        init : function(){
            this.getData().then( () =>{
                this.setRunRate(this.yearToCalculate);
                this.setCount();
                this.timer = setInterval( () =>{
                    this.getCounterInterval();
                    //this.getFactoidInterval();
                }, 50);
                 this.isDataLoaded = true;
            })
        },
        ...mapActions({
            getData : function(dispatch, payload) {
                return dispatch(this.currentRouteModel +'/getData', payload)
            },
            getCounterInterval : function (dispatch, payload) {
                return dispatch(this.currentRouteModel +'/getCounterInterval', payload)
            }
        }),
         ...mapMutations({
            setRunRate (commit, payload) {
                return commit(this.currentRouteModel + '/setRunRate', payload)
            },
            setCount (commit, payload) {
                return commit(this.currentRouteModel + '/setCount', payload)
            }
        })
    },
    computed: {
        ...mapGetters([
            'widget',
        ]),
        currentRouteModel : function() {
            if(!this.$route){
                return;
            }

            if(!this.$route.hasOwnProperty('meta')){
                return;
            }

            if(!this.$route.meta.hasOwnProperty('vuexModel')){
                //console.error('this route is missing a vuexModel in routes/index')
                return;
            }
            return `${this.$route.meta.vuexModel}`;
        },
        fullScreenLink: function() {
            let url = window.location.protocol + "//" + window.location.host + ((window.location.pathname == '/') ? '' : window.location.pathname );
            let hash = URL.drop(window.location.hash, 'type');

            return `${url}/#${hash}`;
        }
    },
    components : {
        navigation,
        barChart,
        widgetBarChart,
        backgrounds
    }
}
</script>